import React from 'react';
import DefaultLayout from '../../layouts/DefaultLayout';
import TemplateColumn, { TemplateColumnWithSidebarContainer } from '../../layouts/TemplateColumn';
import { PageHeader, PageSection, PageSubSection, RelatedContent, CrossLink, IndicationPageIntro, AnchorLinks, SecondaryCallout, KMCurve, ComparisonTable, ReferencesBlock } from '../../components';
import { StaticImage } from 'gatsby-plugin-image';
import UC_2L from '../../components/StudyDesign/__study-design/uc-2l'
import { jobCodes } from '../../util/data/jobCodes';

// SEO INFORMATION
const pageMeta = {
    title: `KEYNOTE-045 - Clinical Trial Results | HCP`,
    keywords: `keynote 045, urothelial carcinoma second-line monotherapy`,
    description: `Health care professionals can view clinical trial results for KEYNOTE-045 in certain patients with locally advanced or metastatic urothelial carcinoma.`,
    schemaJsonLD: [
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-carcinoma-second-line-monotherapy/","@type":"MedicalTrial","name":"KEYNOTE⁠-⁠045","description":"Clinical Findings from KEYNOTE⁠-⁠045","trialDesign":"The efficacy of KEYTRUDA was investigated in KEYNOTE⁠-⁠045, a multicenter, randomized (1:1), active-controlled trial in 542 patients with locally advanced or metastatic urothelial carcinoma with disease progression on or after platinum-containing chemotherapy. The trial excluded patients with autoimmune disease or a medical condition that required immunosuppression."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-carcinoma-second-line-monotherapy/","@type":"MedicalAudience","audienceType":"Health Care Professionals","geographicArea":"This site is intended for health care professionals of the United States, its territories, and Puerto Rico."}`,
        `{"@context":"http://schema.org/","url":"https://www.keytrudahcp.com/efficacy/urothelial-carcinoma-second-line-monotherapy/","@type":"MedicalEntity","guideline":"Pembrolizumab (KEYTRUDA) is recommended as a preferred category 1 subsequent systemic therapy option for locally advanced or mUC post–platinum failure.","recognizingAuthority":"NCCN Clinical Practice Guidelines in Oncology (NCCN Guidelines®)"}`
    ]
}

//from /src/util/data/indications.js
const indicationId = 16;
const jobCode = jobCodes[15].jobCode;

const relatedContent = [
    {
        section: 'Resources',
        link: {
            url: '/resources/mechanism-of-action/',
            label: 'Mechanism of Action'
        }
    },
    {
        section: 'Resources',
        link: {
            url: 'https://www.merckaccessprogram-keytruda.com/',
            label: 'Access & Reimbursement Support'
        }
    }
];

const crossLinkContent = {
    sections: [
        {
            heading: "Additional Clinical Data",
            items: [
                { text: '1L Combination Therapy Advanced Urothelial Cancer', url: '/efficacy/urothelial-cancer-first-line-combination-therapy/' },
                { text: '1L Monotherapy Cisplatin Ineligible', url: '/efficacy/urothelial-carcinoma-first-line-monotherapy/' },
            ]
        },
        {
            heading: "Related Information",
            items: [
                { text: 'Selected Adverse Reactions', url: '/safety/adverse-reactions/urothelial-carcinoma-second-line-monotherapy/' },
                { text: 'Dosing', url: '/dosing/options/' },

                { text: ' PD&#8288;-&#8288;L1 Testing & Scoring', url: '/biomarker-testing/pd-l1/' },
            ]
        },
    ],
    interestLinks: {
        heading: "You Might Be Interested In",
        items: [
            { eyebrow: 'Resources', text: 'Mechanism of Action', url: '/resources/mechanism-of-action/' },
        ],
    }
};

const anchorLinkModelData = [
    { label: 'Clinical Findings from KEYNOTE&#8288;-&#8288;045' },
    { label: 'Study Design for KEYNOTE&#8288;-&#8288;045' },
];

const pageReferences = [
    {
        label: "1.",
        text: "Bellmunt J, de Wit R, Vaughn DJ, et al; for the KEYNOTE&#8288;-&#8288;045 investigators. Pembrolizumab as second-line therapy for advanced urothelial carcinoma. <i>N Engl J Med.</i> 2017;376(11):1015–1026"
    },
    {
        label: "2.",
        text: "<i>Opdivo</i> [package insert]. Princeton, NJ: Bristol-Myers Squibb Company; 2024."
    },
    {
        label: "3.",
        text: "FDA approves updated indication for Merck’s KEYTRUDA® (pembrolizumab) for treatment of certain patients with urothelial carcinoma (bladder cancer). News release. Merck. August 31, 2021. Accessed May 4, 2023. https://www.merck.com/news/fda-approves-updated-indication-for-mercks-keytruda-pembrolizumab-for-treatment-of-certain-patients-with-urothelial-carcinoma-bladder-cancer"
    }
]

const Page = ({ location }) => {

    return (
        <DefaultLayout indicationId={indicationId} jobCode={jobCode} pageMeta={pageMeta} location={location}>
            <div data-template-name="template-e">

                {/* Begin Intro Column */}
                <TemplateColumn id="template-e-intro" maxWidth={824}>
                    <PageHeader title="Clinical Trial Results" />
                    <PageSection bgColor="white">
                        <IndicationPageIntro indicationId={indicationId} />
                        <AnchorLinks items={anchorLinkModelData} />
                    </PageSection>
                </TemplateColumn>
                {/* End Intro Column */}

                <TemplateColumnWithSidebarContainer>
                    {/* Begin Main Column */}
                    <TemplateColumn id="template-e-main">
                        <PageSection bgColor="cloud" title={anchorLinkModelData[0].label}>
                            <SecondaryCallout 
                                title="First and only phase 3 anti–programmed death receptor-1 (PD&#8288;-&#8288;1) trial to demonstrate superior overall survival vs chemotherapy in patients with advanced urothelial carcinoma post–platinum failure<sup>1–3</sup>" 
                                alt=""
                            />

                            <KMCurve
                                title="At a Prespecified Interim Analysis: Kaplan-Meier Estimates of Overall Survival (OS)<sup>1</sup>"
                                limitation="Median follow-up time of 9.0 months (range: 0.2–20.8 months)."
                                hr="HR=0.73 (95% CI, 0.59–0.91; <i>P</i>=0.004)"
                                chartAttribution="From <i>The New England Journal of Medicine</i>, Bellmunt J, de Wit R, Vaughn DJ, et al, for the KEYNOTE&#8288;-&#8288;045 Investigators, Pembrolizumab as second-line therapy for advanced urothelial carcinoma, Vol 376, Pages 1015–1026, Copyright © 2017 Massachusetts Medical Society. Reprinted with permission from Massachusetts Medical Society."
                                definitions="CI = confidence interval; HR = hazard ratio; Q3W = every 3 weeks; UC = urothelial carcinoma."
                                image={() => <StaticImage src="../../assets/uc-2-l-045-interim-km-v-1.png"
                                    placeholder="blurred"
                                    alt="Kaplan-Meier Estimates of Overall Survival (OS) With KEYTRUDA® (pembrolizumab) vs Chemotherapy in KEYNOTE-045" />}
                            />

                            <SecondaryCallout 
                                title="27% reduction in the risk of death with KEYTRUDA vs chemotherapy (HR=0.73; 95% CI, 0.59–0.91; <i>P</i>=0.004)" 
                                text="Number of deaths observed in each arm: 155/270 (57%) with KEYTRUDA vs 179/272 (66%) with chemotherapy" 
                                alt=""
                            />


                            <ComparisonTable title="Overall Survival (OS)" limitation="Median follow-up time was 9.0 months (range: 0.2–20.8 months)" data={[
                                [
                                    {
                                        legendTitle: 'KEYTRUDA (n=270)',
                                    },
                                    {
                                        legendTitle: 'Chemotherapy (n=272)',
                                    },
                                ],
                                [
                                    {
                                        hrCiP: 'HR<sup>a</sup>=0.73 (95% CI, 0.59–0.91; <i>P</i>=0.004)',
                                        colspan: 2,
                                        centerText: true,
                                    },
                                ],
                                [
                                    {
                                        label: 'Median OS',
                                        dataPoint: '10.3 months',
                                        dataSubPoint: '(95% CI, 8.0–11.8)'
                                    },
                                    {
                                        label: 'Median OS',
                                        dataPoint: '7.4 months',
                                        dataSubPoint: '(95% CI, 6.1–8.3)'
                                    },
                                ]
                            ]}
                            />

                            <ComparisonTable title="Progression-free Survival (PFS)" limitation="There was no statistically significant difference in PFS with KEYTRUDA vs chemotherapy"
                                footnotes={[
                                {
                                    label: "a.",
                                    text: "HR ratio (KEYTRUDA compared to chemotherapy) based on the stratified Cox proportional hazard model."
                                }
                            ]}
                            data={[
                                [
                                    {
                                        legendTitle: 'KEYTRUDA (n=270)',
                                    },
                                    {
                                        legendTitle: 'Chemotherapy (n=272)',
                                    },
                                ],
                                    [
                                        {
                                        hrCiP: "HR<sup>a</sup>=0.98; 95% CI, 0.81–1.19; <i>P</i>=0.833",
                                        colspan: 2,
                                        centerText: true,
                                    }
                                    ],
                                [
                                    {
                                        label: 'Events Observed',
                                        dataPoint: '81%',
                                        dataSubPoint: '(218/270)'
                                    },
                                    {
                                        label: 'Events Observed',
                                        dataPoint: '81%',
                                        dataSubPoint: '(219/272)'
                                    },
                                ],
                                [
                                    {
                                        label: 'Median PFS ',
                                        dataPoint: '2.1 months',
                                        dataSubPoint: '(95% CI, 2.0–2.2)'
                                    },
                                    {
                                        label: 'Median PFS ',
                                        dataPoint: '3.3 months',
                                        dataSubPoint: '(95% CI, 2.3–3.5)'
                                    },
                                ]
                            ]}
                            />

                            <SecondaryCallout 
                                title="The phase 3 KEYNOTE&#8288;-&#8288;045 trial was stopped early due to superior OS with KEYTRUDA vs chemotherapy.<sup>1</sup>" 
                                alt=""    
                            />

                            <ComparisonTable title="Objective Response Rate (ORR) at a Prespecified Interim Analysis" limitation="Superior ORR vs chemotherapy in locally advanced or metastatic Urothelial Carcinoma post–platinum failure" data={[
                                [
                                    {
                                        legendTitle: 'KEYTRUDA (n=270)',
                                    },
                                    {
                                        legendTitle: 'Chemotherapy (n=272)',
                                    },
                                ],
                                [
                                    {
                                        label: 'ORR',
                                        dataPoint: '21%',
                                        dataSubPoint: '(95% CI, 16–27)'
                                    },
                                    {
                                        label: 'ORR',
                                        dataPoint: '11%',
                                        dataSubPoint: '(95% CI, 8–16)'
                                    },
                                ],
                                [
                                    {
                                        label: 'Complete Response',
                                        dataPoint: '7%',
                                    },
                                    {
                                        label: 'Complete Response',
                                        dataPoint: '3%',
                                    },
                                ],
                                [
                                    {
                                        label: 'Partial Response',
                                        dataPoint: '14%',
                                    },
                                    {
                                        label: 'Partial Response',
                                        dataPoint: '8%',
                                    },
                                ],
                                [
                                    {
                                        dataPoint: '<i>P</i>-value=0.002',
                                        colspan: 2,
                                        centerText: true,
                                    },
                                ]
                            ]}
                            />

                            <ComparisonTable
                                title="Duration of Response (DOR) at a Prespecified Interim Analysis"
                                definitions="+ Denotes ongoing response."
                                data={[
                                    [
                                        {
                                            legendTitle: 'KEYTRUDA (n=270)',
                                        },
                                        {
                                            legendTitle: 'Chemotherapy (n=272)',
                                        },
                                    ],
                                    [
                                        {
                                            label: 'Median DOR',
                                            dataPoint: 'Not reached',
                                            dataSubPoint: '(range: 1.6+ to 15.6+)'
                                        },
                                        {
                                            label: 'Median DOR',
                                            dataPoint: '4.3 months',
                                            dataSubPoint: '(range: 1.4+ to 15.4+)'
                                        },
                                    ]
                                ]}
                            />

                            <PageSubSection title={anchorLinkModelData[1].label}>
                                <UC_2L />
                            </PageSubSection>
                        </PageSection>
                    </TemplateColumn>

                    {/* End Main Column */}
                    <TemplateColumn id="template-e-sidebar">
                        <PageSection bgColor="mist">
                            <CrossLink {...crossLinkContent} />
                        </PageSection>
                    </TemplateColumn>

                </TemplateColumnWithSidebarContainer>

                <TemplateColumn id="template-e-secondary">
                    <PageSection bgColor="keytrudaGreen">
                        <RelatedContent items={relatedContent} />
                    </PageSection>
                    <PageSection bgColor="mist">
                        <ReferencesBlock references={pageReferences} />
                    </PageSection>
                </TemplateColumn>
            </div>
        </DefaultLayout>
    );
};

export default Page;
