import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyCopyBlock } from '../index';

const UC_2L = () => (
    
    <ComponentWrapper>
        {/* <StudyDesignOverview title="Study Overview" description="A multicenter, randomized, active-controlled, open-label trial<sup>1</sup>" data={[
            [
                {
                    title: 'Patient Population',
                    description: `
                        <p>542 patients with locally advanced or mUC with disease progression on or after platinum-containing chemotherapy.</p>
                        <p>The trial excluded patients with autoimmune disease or a medical condition that required immunosuppression.</p>
                    `,
                    type: 'box-gradient',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray'
                }
            ],
            [
                {
                    title: 'Randomized (1:1) (N=542)',
                    colspan: 2
                }
            ],
            [
                {
                    type: 'arrow-gray'
                },
                {
                    type: 'arrow-gray'
                }
            ],
            [
                {
                    title: 'Treatment',
                    type: 'box-green box-small-title'
                },
                {
                    title: 'Treatment',
                    type: 'box-small-title'
                }
            ],
            [
                {
                    type: 'arrow-green'
                },
                {
                    type: 'arrow-gray'
                }
            ],
            [
                {
                    title: 'Treatment Duration',
                    type: 'box-small-title'
                },
                {
                    title: 'Treatment Duration',
                    type: 'box-small-title'
                }
            ]
        ]} />
    
        <StudyDesignArms 
            title="Study Arms" 
            arms={[
                {
                    title: "KEYTRUDA",
                    data: [
                        [
                            {
                                title: 'Initial Treatment',
                                description: `KEYTRUDA 200&nbsp;mg IV Q3W (n=270)`,
                                type: 'box-green'
                            }
                        ],
                        [
                            {
                                type: 'arrow-green'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `Treatment continued until disease progression, unacceptable toxicity, or for up to 24 months in patients without disease progression.`
                            }
                        ]
                    ]
                },
                {
                    title: "Chemotherapy",
                    data: [
                        [
                            {
                                title: 'Treatment',
                                description: `Investigator’s choice of platinum-containing chemotherapy IV, including paclitaxel 175&nbsp;mg/m<sup>2</sup>, docetaxel 75&nbsp;mg/m<sup>2</sup>, or vinflunine 320&nbsp;mg/m<sup>2</sup> (n=272)`
                            }
                        ],
                        [
                            {
                                type: 'arrow-gray'
                            }
                        ],
                        [
                            {
                                title: 'Treatment Duration',
                                description: `Treatment continued until disease progression, unacceptable toxicity, or for up to 24 months in patients without disease progression.`
                            }
                        ]
                    ]
                }
            ]} 
        />
    
        <StudyCopyBlock 
            title="End Points"
            description={` 
                <p>Major efficacy outcome measures: OS and PFS</p>
                <p>Additional efficacy outcome measures: ORR and DOR</p>
            `} 
        />
    
        <StudyCopyBlock 
            title="Study Design Details"
            description={`
                <p>Investigator’s choice of any of the following chemotherapy regimens given intravenously Q3W: paclitaxel 175&nbsp;mg/m<sup>2</sup>, docetaxel 75&nbsp;mg/m<sup>2</sup>, or vinflunine 320&nbsp;mg/m<sup>2</sup>.</p>
                <p>Assessment of tumor status was performed at 9 weeks after randomization, then every 6 weeks through the first year, followed by every 12 weeks thereafter.</p>
                
                <ul>
                    <li>PFS and ORR were assessed by blinded independent central review (BICR) per Response Evaluation Criteria In Solid Tumors (RECIST) v1.1 modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ.</li>
                <ul>
                
                <p>Patients with initial radiographic disease progression could receive additional doses of treatment during confirmation of progression unless disease progression was symptomatic, was rapidly progressive, required urgent intervention, or occurred with a decline in performance status.</p>
                <p>15% of patients received treatment after disease progression following prior platinum-containing neoadjuvant or adjuvant chemotherapy.</p>
                
                <p><strong>A range of patients was evaluated in KEYNOTE&#8288;-&#8288;045<sup>b</sup></strong></p>
                
                <ul>
                    <li>Patient age ≥65 years: 58%</li>
                    <li>Median age: 66 years (range: 26–88 years)</li>
                    <li>ECOG PS 1: 56%</li>
                    <li>Liver metastases: 34%</li>
                    <li>Prior cisplatin therapy<sup>c</sup>: 76%</li>
                    <li>87% of patients had visceral metastases</li>
                </ul>
            `} 
            footnotes={[
                {
                    label:'b.',
                    text: `Among the 542 randomized patients, 74% were male; 72% were white and 23% were Asian; 96% had M1 disease and 4% had M0 disease; 86% had a primary tumor in the lower tract and 14% had a primary tumor in the upper tract; 21% had received ≥2 prior systemic regimens in the metastatic setting.`
                },
                {
                    label:'c.',
                    text: `Twenty-three percent of patients had prior carboplatin therapy and 1% were treated with other platinum-based regimens.`
                }
            ]}
            definitions={`
                ECOG PS = Eastern Cooperative Oncology Group performance status.
            `} 
        /> */}
         <StudyCopyBlock 
            description={`
                <p>The efficacy of KEYTRUDA was investigated in KEYNOTE&#8288;-&#8288;045, a multicenter, randomized (1:1), active-controlled trial in 542 patients with locally advanced or metastatic urothelial carcinoma with disease progression on or after platinum-containing chemotherapy. The trial excluded patients with autoimmune disease or a medical condition that required immunosuppression.</p>
                <p>Patients were randomized to receive either KEYTRUDA 200&nbsp;mg every 3 weeks (n=270) or investigator’s choice of any of the following chemotherapy regimens all given intravenously every 3 weeks (n=272): paclitaxel 175&nbsp;mg/m<sup>2</sup> (n=90), docetaxel 75&nbsp;mg/m<sup>2</sup> (n=92), or vinflunine 320&nbsp;mg/m<sup>2</sup> (n=90). Treatment continued until unacceptable toxicity or disease progression. Patients with initial radiographic disease progression could receive additional doses of treatment during confirmation of progression unless disease progression was symptomatic, was rapidly progressive, required urgent intervention, or occurred with a decline in performance status. Patients without disease progression could be treated for up to 24 months. Assessment of tumor status was performed at 9 weeks after randomization, then every 6 weeks through the first year, followed by every 12 weeks thereafter. The major efficacy outcomes were OS and PFS as assessed by blinded independent central review (BICR) per Response Evaluation Criteria In Solid Tumors (RECIST) v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ. Additional efficacy outcome measures were ORR as assessed by BICR per RECIST v1.1, modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ, and DOR.</p>
                <p><b>A range of patients was evaluated in KEYNOTE&#8288;-&#8288;045</b></p>
                <ul>
                    <li>Median age: 66 years (range: 26–88 years)</li>
                    <li>Patient age ≥65 years: 58%</li>
                    <li>74% male, 72% White, 23% Asian</li>
                    <li>ECOG PS 0: 42%</li>
                    <li>ECOG PS 1: 56%</li>
                    <li>96% had M1 disease and 4% had M0 disease</li>
                    <li>87% of patients had visceral metastases</li>
                    <li>Liver metastases: 34%</li>
                    <li>86% had a primary tumor in the lower tract and 14% had a primary tumor in the upper tract</li>
                    <li>15% of patients received treatment after disease progression following prior platinum-containing neoadjuvant or adjuvant chemotherapy</li>
                    <li>21% had received ≥2 prior systemic regimens in the metastatic setting</li>
                    <li>Prior cisplatin therapy: 76%</li>
                    <li>23% of patients had prior carboplatin therapy, and 1% were treated with other platinum-based regimens</li>
                </ul>

            `}          
            definitions={`
                ECOG PS = Eastern Cooperative Oncology Group performance status.
            `} 
        />

    </ComponentWrapper>
);

export default UC_2L;
